// reactstrap components
import { Container, Row, Col } from "reactstrap";

function MainFooter () {
    return (
        <>
            <Container fluid>
                <footer className="footer pt-0">
                    <Row className="align-items-center justify-content-lg-between">
                        <Col lg="6">
                            <div className="copyright text-center text-lg-left text-muted">
                            </div>
                        </Col>
                    </Row>
                </footer>
            </Container>
        </>
    );
}

export default MainFooter;
