import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import Heart from "assets/img/icons/Heart";
import Download from "assets/img/icons/Download";
// core components

class EnterMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      URL_Id: this.props.match.params.URL_Id,
      show: false,
      like: false,
    };
    this.Enter = this.Enter.bind(this);
  }

  Enter() {
    window.scrollTo(0, 0);
    const contentElement = document.getElementById(`page2`);
    if (contentElement.style.opacity === "0") {
      contentElement.style.opacity = "1";
      contentElement.style.transform = "translateX(0%)";
    } else {
      contentElement.style.opacity = "0";
      contentElement.style.transform = "translateX(100%)";
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="flex flex-col h-screen overflow-hidden">
        <Helmet>
          <title>Home</title>
        </Helmet>
        <div className="relative h-[calc(100%-44px)]">
          <div className="flex flex-row mx-4 h-full">
            <div className="z-20 flex flex-col pr-8 mt-4 pt-8 pb-4 justify-between bg-primary basis-1/2 grow rounded-l-[1.875rem] overflow-hidden mb-8 shadow-container">
              <div className="flex flex-row">
                <h1 className="w-fit writing-vertical text-[8rem] font-semibold -ml-16">
                  七彩玉
                </h1>
                <div className="whitespace-nowrap font-merriweather -ml-1.5 mt-auto mb-60 h-fit origin-top-left rotate-90 text-red-600 text-3xl leading-7">
                  Seven Colour Gen
                </div>
              </div>
              <button
                className="flex flex-col text-xl items-end"
                onClick={this.Enter}
              >
                <p className="-mb-2 font-merriweather mr-7">enter</p>
                <img
                  className="w-20"
                  src={require("assets/img/icons/arrow.svg").default}
                  alt="arrow"
                />
              </button>
            </div>
            <div className="flex mt-8 pt-4 flex-col mb-4 bg-second basis-1/2 grow rounded-r-[1.875rem] overflow-x-hidden pr-10 shadow-container">
              <img
                className="w-full h-[calc(100%-64px)] "
                src={require("assets/img/photo/slab3.png").default}
                alt="slab3"
              />
            </div>
          </div>

          <div
            id="page2"
            className="absolute z-20 left-0 ml-4 top-4 pb-8 h-full flex flex-col pr-0 removable-object"
            style={{ opacity: 0, transform: "translateX(-100%)" }}
          >
            <div className="flex flex-col bg-primary h-full shadow-container rounded-l-[1.875rem] pl-6 pb-6">
              <div className="flex flex-row h-56 items-end overflow-x-hidden">
                <h2 className="writing-vertical w-fit text-5xl font-semibold mr-2.5 mb-2">
                  七彩玉
                </h2>
                <img
                  className="w-full h-full"
                  src={require("assets/img/photo/slab4.png").default}
                  alt="slab4"
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <p className="text-red-600 text-lg font-merriweather">
                  Seven Colour Gen
                </p>
                <div className="flex flex-row items-center gap-x-3 mr-4">
                  <a
                    className="text-gray-400 active:text-blue-900"
                    href={require("assets/img/photo/slab4.png").default}
                    download
                  >
                    <Download />
                  </a>
                  <button
                    className={
                      this.state.like ? "text-red-600" : "text-gray-400"
                    }
                    onClick={() => {
                      this.setState({ like: !this.state.like });
                    }}
                  >
                    <Heart />
                  </button>
                </div>
              </div>
              <p className="font-medium text-sm my-4">
                水晶玉石類 | 產地：中國
              </p>
              <div className="overflow-y-auto">
                <p class="font-medium mb-3">
                  七彩玉的質地堅硬，其硬度平均都在莫氏6.2度以上，品質好的可以高達7度。七彩玉石硅化程度極高，五顏六色、異彩繽紛、渾然天成。
                </p>
                <p class="font-medium">
                  其中含有多種礦物化學成分，每一成分都能演變成一種顏色，七彩玉的色彩鮮豔程度是絕無僅有的，雖然名為七彩玉，但是顏色卻遠不止七種。是玉石石材品種中顏色最豐富多彩的一款，質地細膩、透光，有彩雲般的紋路。適用於室內牆，展現出高雅、脫俗的風格品味。
                </p>
              </div>
              {/* <button className="flex flex-row items-center text-xl self-end mr-4">
              <p className="mr-1">類似石材</p>
              <img
                className="w-12"
                src={require("assets/img/icons/arrow.svg").default}
                alt="arrow"
              />
            </button> */}
            </div>
          </div>
        </div>
        <div className="my-2 text-lg font-inter text-center">
          煌奇石業 Huang Chi Stone
        </div>
      </div>
    );
  }
}

export default EnterMain;
