import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/index.css";

// import AdminLayout from "layouts/Admin.js";
import MainLayout from "layouts/Main";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
      <Route path="/" render={(props) => <MainLayout {...props} />} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
