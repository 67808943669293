import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { getTAG, getTime, toHHMMSS, zhToenTime, enTozhTime } from "variables/TableData.js";

class TimelineMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            EPC: "",
            dataTable: [{
                "StationName": "",
                "time_in": "",
                "time_out": "",
                "during": ""
            }],
            language: "en",
            URL_Id: this.props.match.params.URL_Id
        };
        this.loadData = this.loadData.bind(this);
        this.Change = this.Change.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    async loadData() {
        var data = await getTAG(this.state.URL_Id);
        console.log(data);
        var list = [];
        var language = this.state.language;
        var enList = ["Pressing", "Curing", "Cutting", "Polishing"];
        var zhList = ["壓製", "烘烤", "切邊", "拋光"];
        if (data !== 0) {
            if (data.pressIn !== "") {
                let obj = {};
                obj.StationName = language === "en" ? enList[0] : zhList[0];
                obj.time_in = data.pressIn;
                obj.time_out = "";
                obj.during = "";
                if (data.pressOut !== "") {
                    obj.time_out = data.pressOut;
                    obj.during = getTime(data.pressOut) - getTime(data.pressIn);
                    obj.during = toHHMMSS(obj.during);
                }
                list.push(obj);
            }
            // if (data.pressOut !== "") {
            //     let obj = {};
            //     obj.StationName = "壓出";
            //     obj.time_in = data.pressOut;
            //     obj.during = "";
            //     if (data.pressIn !== "") {
            //         obj.during = getTime(data.pressOut) - getTime(data.pressIn);
            //         obj.during = toHHMMSS(obj.during);
            //     }
            //     list.push(obj);
            // }
            if (data.cureIn !== "") {
                let obj = {};
                obj.StationName = language === "en" ? enList[1] : zhList[1];
                obj.time_in = data.cureIn;
                obj.time_out = "";
                obj.during = "";
                if (data.cureOut !== "") {
                    obj.time_out = data.cureOut;
                    obj.during = getTime(data.cureOut) - getTime(data.cureIn);
                    obj.during = toHHMMSS(obj.during);
                }
                list.push(obj);
            }
            // if (data.cureOut !== "") {
            //     let obj = {};
            //     obj.StationName = "烘出";
            //     obj.time_in = data.cureOut;
            //     obj.during = "";
            //     if (data.cureIn !== "") {
            //         obj.during = getTime(data.cureOut) - getTime(data.cureIn);
            //         obj.during = toHHMMSS(obj.during);
            //     }
            //     list.push(obj);
            // }
            if (data.cutIn !== "") {
                let obj = {};
                obj.StationName = language === "en" ? enList[2] : zhList[2];
                obj.time_in = data.cutIn;
                obj.time_out = "";
                obj.during = "";
                if (data.cutOut !== "") {
                    obj.time_out = data.cutOut;
                    obj.during = getTime(data.cutOut) - getTime(data.cutIn);
                    obj.during = toHHMMSS(obj.during);
                }
                list.push(obj);
            }
            // if (data.cutOut !== "") {
            //     let obj = {};
            //     obj.StationName = "切出";
            //     obj.time_in = data.cutOut;
            //     obj.during = "";
            //     if (data.cutIn !== "") {
            //         obj.during = getTime(data.cutOut) - getTime(data.cutIn);
            //         obj.during = toHHMMSS(obj.during);
            //     }
            //     list.push(obj);
            // }
            if (data.polishIn !== "") {
                let obj = {};
                obj.StationName = language === "en" ? enList[3] : zhList[3];
                obj.time_in = data.polishIn;
                obj.time_out = "";
                obj.during = "";
                if (data.polishOut !== "") {
                    obj.time_out = data.polishOut;
                    obj.during = getTime(data.polishOut) - getTime(data.polishIn);
                    obj.during = toHHMMSS(obj.during);
                }
                list.push(obj);
            }
            // if (data.polishOut !== "") {
            //     let obj = {};
            //     obj.StationName = "光出";
            //     obj.time_in = data.polishOut;
            //     obj.during = "";
            //     if (data.polishIn !== "") {
            //         obj.during = getTime(data.polishOut) - getTime(data.polishIn);
            //         obj.during = toHHMMSS(obj.during);
            //     }
            //     list.push(obj);
            // }
            list.sort((a, b) => (getTime(a.time_in) > getTime(b.time_in)) ? 1 : ((getTime(b.time_in) > getTime(a.time_in)) ? -1 : 0))
            this.setState({ EPC: data.EPC, dataTable: list });
        }
        else {
            var str = language === "en" ? "Slab does not exist" : "沒有此石板！";
            this.setState({ EPC: str, dataTable: [] });
        }
    }

    Change() {

    };

    changeLanguage() {
        var language = this.state.language === "en" ? "zh" : "en";
        var data = this.state.dataTable;
        var enList = ["Pressing", "Curing", "Cutting", "Polishing"];
        var zhList = ["壓製", "烘烤", "切邊", "拋光"];
        var EPC = this.state.EPC;
        if (EPC === "Not have this board!")
            EPC = "沒有此石板！";
        else if (EPC === "沒有此石板！")
            EPC = "Not have this board!";
        data.forEach(element => {
            if (language === "en") {
                element.StationName = enList[zhList.indexOf(element.StationName)];
                element.time_in = zhToenTime(element.time_in);
                element.time_out = zhToenTime(element.time_out);
            }
            else {
                element.StationName = zhList[enList.indexOf(element.StationName)];
                element.time_in = enTozhTime(element.time_in);
                element.time_out = enTozhTime(element.time_out);
            }
        });
        this.setState({ language: language, EPC: EPC, dataTable: data });
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lastTime !== this.state.lastTime) {
            this.Change();
        }
    }

    componentWillUnmount() {
    }

    render() {
        let duringTemplate = (data) => {
            if (data !== "") {
                if (this.state.language === "zh") {
                    return <div>
                        <small className="text-muted">
                            <i className="mr-1" /> 耗時 {data}
                        </small>
                    </div>
                }
                else if (this.state.language === "en") {
                    return <div>
                        <small className="text-muted">
                            <i className="mr-1" /> Duration {data}
                        </small>
                    </div>
                }
            }
        }
        let DivTemplate = this.state.dataTable.map((value, index, array) => {
            return <div key={index} className="timeline-block">
                <span className="timeline-step badge-info">
                    <i className="ni ni-books" />
                </span>
                <div className="timeline-content">
                    <div className="d-flex justify-content-between pt-1">
                        <div>
                            <span className="text-muted text-m font-weight-bold">
                                {value.StationName}
                            </span>
                        </div>
                    </div>
                    <div>
                        <small className="text-muted">
                            <i className="fas fa-clock mr-1" />{value.time_in}
                        </small>
                    </div>
                    <div>
                        <small className="text-muted">
                            <i className="mr-1" />- {value.time_out}
                        </small>
                    </div>
                    {duringTemplate(value.during)}
                </div>
            </div>
        }
        );

        return (
            <>
                <Helmet>
                    <title>Timeline</title>
                </Helmet>
                <SimpleHeader name="" />
                <Container className="mt--6" fluid>
                    <label>
                        <Button
                            className="buttons-html5"
                            color="default"
                            size="sm"
                            onClick={this.changeLanguage}
                        >
                            {this.state.language === "en" ? "中文" : "English"}
                        </Button>
                    </label>
                    <Card>
                        <CardHeader>
                            <h5 className="h3 mb-0">{this.state.EPC}</h5>
                        </CardHeader>
                        <CardBody>
                            <div
                                className="timeline timeline-one-side"
                                data-timeline-axis-style="dashed"
                                data-timeline-content="axis"
                            >
                                {DivTemplate}
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </>
        )
    }
}

export default TimelineMain;