export default function Heart() {
  return (
    <svg
      className="fill-current"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9337 3.94874L12 4.88624L11.0662 3.94874C9.92248 2.80875 8.40372 2.17875 6.79122 2.17875C5.17872 2.17875 3.65998 2.80875 2.51996 3.94874C0.164976 6.3075 0.164976 10.14 2.51996 12.495L11.7337 21.7125C11.8087 21.7837 11.9025 21.8212 12 21.8212C12.0937 21.8212 12.1912 21.7837 12.2625 21.7125L21.48 12.495C22.62 11.355 23.25 9.83624 23.25 8.22375C23.25 6.61125 22.62 5.09248 21.48 3.94874C19.1212 1.59375 15.2887 1.59375 12.9337 3.94874Z" />
    </svg>
  );
}
