import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
    Button,
    Row,
    Card,
    CardHeader,
    CardBody,
    Container,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {getTAG} from "variables/TableData.js"

class UserMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            EPC: "EPC",
            URL_Id: this.props.match.params.URL_Id
        };
        this.loadData = this.loadData.bind(this);
        this.Change = this.Change.bind(this);
        this.Registration = this.Registration.bind(this);
    }

    async loadData() {
        var data = await getTAG(this.state.URL_Id);
        if(data !== 0)
            this.setState({EPC: data.EPC});
    }

    Change() {

    };

    Registration() {
        this.setState({
            alert:
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Registration Successful."
                    onConfirm={() => this.setState({ alert: null })}
                    onCancel={() => this.setState({ alert: null })}
                    confirmBtnBsStyle="default"
                    btnSize=""
                >
                </ReactBSAlert>
        });
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <>
                {this.state.alert}
                <Helmet>
                    <title>End User</title>
                </Helmet>
                <Container className="mt-2" fluid>
                <Card>
                <CardBody>
                <div className="d-flex justify-content-center mt-2">
                    <p className="h1" style={{"display": "block"}}>
                        Authenticity Code:
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <p className="h1" style={{"display": "block"}}>
                        {this.state.URL_Id}
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <p className="text-m font-weight-bold mb-1">Color : Calacatta Gold</p>
                </div>
                <div className="d-flex justify-content-center">
                    <img src={require("assets/img/photo/slab1.png").default} style={{"height":"200px","width":"300px", "display": "block"}} alt="slab1" />
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <img src={require("assets/img/photo/slab2.png").default} style={{"height":"200px","width":"300px", "display": "block"}} alt="slab2" />
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <Button onClick={this.Registration} color="default">Warranty Registration</Button>
                </div>
                </CardBody>
                    </Card>
                </Container>
                <div className="copyright text-center text-muted mt-4">
                    ©2022 Enabl.us
                </div>
            </>
        )
    }
}

export default UserMain;