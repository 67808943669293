import ButtonMain from "views/ButtonMain.js";
import UserMain from "views/UserMain.js";
import DistMain from "views/DistMain.js";
import TimelineMain from "views/TimelineMain.js";
import EnterMain from "views/EnterMain.js";

const routesMain = [
  //   {
  //     path: "tag/:URL_Id/user",
  //     name: "User",
  //     icon: "ni ni-bullet-list-67 text-grey",
  //     component: UserMain,
  //     layout: "/",
  //   },
  //   {
  //     path: "tag/:URL_Id/dist",
  //     name: "Distributors",
  //     icon: "ni ni-bullet-list-67 text-grey",
  //     component: DistMain,
  //     layout: "/",
  //   },
  //   {
  //     path: "tag/:URL_Id/timeline",
  //     name: "Timeline",
  //     icon: "ni ni-bullet-list-67 text-grey",
  //     component: TimelineMain,
  //     layout: "/",
  //   },
  //   {
  //     path: "tag/:URL_Id",
  //     name: "Buttons",
  //     icon: "ni ni-bullet-list-67 text-grey",
  //     component: ButtonMain,
  //     layout: "/",
  //   },
  {
    path: "tag/:URL_Id",
    name: "Enter Page",
    icon: "ni ni-bullet-list-67 text-grey",
    component: EnterMain,
    layout: "/",
  },
];

export default routesMain;
