import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
    Collapse,
    Navbar,
    NavItem,
    Nav,
    Container
} from "reactstrap";

function MainNavbar ({ theme, sidenavOpen, toggleSidenav }) {
    return (
        <>
            <Navbar
                className={classnames(
                    "navbar-top navbar-expand border-bottom",
                    { "navbar-dark bg-blue2": theme === "dark" },
                    { "navbar-light bg-secondary": theme === "light" }
                )}
            >
                <Container fluid>
                    <Collapse navbar isOpen={true}>
                        <Nav className="align-items-center ml-md-auto" navbar>
                            <NavItem className="d-xl-none">
                                <div
                                    className={classnames(
                                        "pr-3 sidenav-toggler",
                                        { active: sidenavOpen },
                                        { "sidenav-toggler-dark": theme === "light" }
                                    )}
                                    onClick={toggleSidenav}
                                >
                                </div>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

MainNavbar.defaultProps = {
    toggleSidenav: () => { },
    sidenavOpen: false,
    theme: "dark",
};
MainNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"]),
};

export default MainNavbar;
