import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import {
    Button,
    Row,
    Badge,
    Card,
    CardHeader,
    CardBody,
    Container,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

class DistMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            URL_Id: this.props.match.params.URL_Id
        };
        this.loadData = this.loadData.bind(this);
        this.Change = this.Change.bind(this);
    }

    async loadData() {

    }

    Change() {

    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    render() {

        return (
            <>
                <Helmet>
                    <title>Distributor</title>
                </Helmet>
                <Container className="mt-2" fluid>
                    <Card>
                        {/* <CardHeader className="h1">EPC</CardHeader> */}
                        <CardBody>
                            <p className="text-m font-weight-bold mb-1">Production Start:</p>
                            <p className="text-muted text-m font-weight-bold mb-1">March 31, 2022 00:54:16</p>
                            <p className="text-m font-weight-bold mb-1">Final Polishing:</p>
                            <p className="text-muted text-m font-weight-bold mb-1">March 31, 2022 13:44:43</p>
                            <p className="text-m font-weight-bold mb-1">Grade : B</p>
                            <p className="text-m font-weight-bold mb-1">Defects :
                                <Badge className="badge-lg ml-2 font-weight-bold" color="default" pill>
                                    Vein
                                </Badge>
                                <Badge className="badge-lg font-weight-bold" color="primary" pill>
                                    Pits
                                </Badge>
                                <Badge className="badge-lg font-weight-bold" color="info" pill>
                                    Mark
                                </Badge>
                            </p>
                            <p className="text-m font-weight-bold mb-1">Color : Calacatta Gold</p>
                            <img src={require("assets/img/photo/slab1.png").default} style={{"height":"200px","width":"300px", "display": "block"}} alt="slab1" />
                            <p></p>
                            <img src={require("assets/img/photo/slab2.png").default} style={{"height":"200px","width":"300px", "display": "block"}} alt="slab2" />
                        </CardBody>
                    </Card>
                </Container>
                <div className="copyright text-center text-muted mt-4">
                    ©2022 Enabl.us
                </div>
            </>
        )
    }
}

export default DistMain;