import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { useHistory, Link } from "react-router-dom";
import {
    Button,
    Col,
    Row,
    Card,
    CardHeader,
    CardBody,
    Container,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

class ButtonMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            URL_Id: this.props.match.params.URL_Id
        };
        this.loadData = this.loadData.bind(this);
        this.Change = this.Change.bind(this);
    }

    async loadData() {

    }

    Change() {

    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Home</title>
                </Helmet>
                <Container className="mt-2" fluid>
                    <Card>
                        {/* <CardHeader className="h1">EPC</CardHeader> */}
                        <CardBody>
                            <div className="d-flex justify-content-center mt-2">
                                <Link to={`/tag/${this.state.URL_Id}/user`} className="btn btn-default">End User</Link>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <Link to={`/tag/${this.state.URL_Id}/dist`} className="btn btn-default">Distributor</Link>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <Link to={`/tag/${this.state.URL_Id}/timeline`} className="btn btn-default">Timeline</Link>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
                <div className="copyright text-center text-muted mt-4">
                    ©2022 Enabl.us
                </div>
            </>
        )
    }
}

export default ButtonMain;