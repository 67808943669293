export default function Download() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      className="fill-current"
    >
      <g clip-path="url(#clip0_1_27)">
        <path
          d="M17.1853 10.4837C17.0689 10.2264 16.813 10.0625 16.5313 10.0625H13.6563V0.71875C13.6563 0.322 13.3343 0 12.9375 0H10.0625C9.66578 0 9.34378 0.322 9.34378 0.71875V10.0625H6.46878C6.18703 10.0625 5.93115 10.2278 5.81471 10.4837C5.69684 10.741 5.74284 11.0414 5.92828 11.2542L10.9595 17.0042C11.0961 17.1609 11.293 17.25 11.5 17.25C11.707 17.25 11.904 17.1594 12.0405 17.0042L17.0718 11.2542C17.2587 11.0429 17.3018 10.741 17.1853 10.4837Z"
          className="fill-current"
        />
        <path
          d="M19.4062 15.8125V20.125H3.59375V15.8125H0.71875V21.5625C0.71875 22.3574 1.36275 23 2.15625 23H20.8438C21.6387 23 22.2812 22.3574 22.2812 21.5625V15.8125H19.4062Z"
          className="fill-current"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_27">
          <rect width="23" height="23" className="fill-current" />
        </clipPath>
      </defs>
    </svg>
  );
}
