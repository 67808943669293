import { object } from 'prop-types';

const moment = require('moment');
// const momentLocale = require('moment/locale/zh-tw');
moment.locale('en-US');

export function toHHMMSS(diff) {
    var hours = Math.floor(diff / 3600000);
    var minutes = Math.floor((diff - (hours * 3600000)) / 60000);
    var seconds = Math.floor((diff - (hours * 3600000) - (minutes * 60000)) / 1000);
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
}

export function getTime(date) {
    date = date.replace('年', '/');
    date = date.replace('月', '/');
    date = date.replace('日', '/');
    return moment(date).valueOf();
}

export function enTozhTime(date) {
    if (date === "")
        return date;
    var momentLocale = require('moment/locale/zh-tw');
    moment.locale('zh-tw');
    return moment(date).format("LL[\r\n] HH:mm:ss");
}

export function zhToenTime(date) {
    if (date === "")
        return date;
    date = date.replace('年', '/');
    date = date.replace('月', '/');
    date = date.replace('日', '/');
    moment.locale('en-US');
    return moment(date).format("LL[\r\n] HH:mm:ss");
}

let newSlabObject = () => {
    return {
        "line": 1,
        "pressIn": "March 31, 2022\r\n 00:54:16",
        "pressInSignal": -47,
        "pressOut": "March 31, 2022\r\n 01:03:05",
        "cureIn": "March 31, 2022\r\n 01:05:54",
        "cureOut": "March 31, 2022\r\n 03:12:31",
        "cutIn": "March 31, 2022\r\n 08:29:03",
        "cutOut": "March 31, 2022\r\n 08:35:44",
        "polishIn": "March 31, 2022\r\n 09:40:30",
        "polishOut": "March 31, 2022\r\n 13:44:43",
        "polishInA": "March 31, 2022\r\n 09:40:30",
        "polishInASignal": -29,
        "polishOutA": "March 31, 2022\r\n 13:44:43",
        "polishOutASignal": -44,
        "polishInB": "March 31, 2022\r\n 09:40:56",
        "polishInBSignal": -32,
        "polishOutB": "March 31, 2022\r\n 13:44:33",
        "polishOutBSignal": -28,
        "polishLine": 1,
        "polishRotation": "A",
        "EPC": "00-63"
    }
}

export async function getTAG(URL_Id) {
    // try {
    //     const res = await fetch("https://ty-rd.azurewebsites.net/api/GetTAG/" + URL_Id);

    //     if (res.status >= 500) {
    //         throw new Error("Bad response from server");
    //     }

    //     let tagData = await res.json();
    //     let slab = newSlabObject();
    //     if (tagData.EPC !== null) {
    //         slab.EPC = tagData.EPC.substring(tagData.EPC.length - 4);
    //         slab.EPC = slab.EPC.substring(0, 2) + "-" + slab.EPC.substring(2);
    //     }
    //     else
    //         return 0;
    //     let data = tagData.history;
    //     if (data !== []) {
    //         for (let i = 0; i < data.length; i++) {
    //             switch (data[i].MAC) {
    //                 case "98:84:e3:e2:28:b4":
    //                     if (slab.polishInA === "") {
    //                         slab.polishInA = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 2 A]");
    //                         slab.polishLine = 2;
    //                         slab.polishInASignal = data[i].RSSI;
    //                     }
    //                     break;
    //                 case "38:d2:69:dc:d8:73":
    //                     if (slab.polishInB === "") {
    //                         slab.polishInB = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 2 B]");
    //                         slab.polishLine = 2;
    //                         slab.polishInBSignal = data[i].RSSI;
    //                     }
    //                     break;
    //                 case "38:d2:69:dd:2f:f3":
    //                     slab.polishOutA = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 2 A]");
    //                     slab.polishOutASignal = data[i].RSSI;
    //                     slab.polishLine = 2;
    //                     break;
    //                 case "38:d2:69:dd:3f:96":
    //                     slab.polishOutB = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 2 B]");
    //                     slab.polishOutBSignal = data[i].RSSI;
    //                     slab.polishLine = 2;
    //                     break;
    //                 case "38:d2:69:d4:0f:7c":
    //                     if (slab.line === 1) {
    //                         slab.cutIn = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 1]");
    //                     }
    //                     break;
    //                 case "38:d2:69:dc:c9:fc":
    //                     if (slab.line === 1) {
    //                         slab.cutOut = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 1]");
    //                     }
    //                     break;
    //                 case "38:d2:69:da:9f:64":
    //                     if (data[i].RSSI > slab.pressInSignal) {
    //                         slab.pressInSignal = data[i].RSSI;
    //                         slab.pressIn = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 1]");
    //                         slab.line = 1;
    //                     }
    //                     break;
    //                 case "38:d2:69:e0:b2:ed":
    //                     if (slab.line === 1) {
    //                         slab.pressOut = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 1]");
    //                     }
    //                     break;
    //                 case "38:d2:69:da:b4:3a":
    //                     if (slab.line === 1 && slab.cureIn === "") {
    //                         slab.cureIn = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 1]");
    //                     }
    //                     break;
    //                 case "38:d2:69:e0:3b:3a":
    //                     if (slab.line === 1) {
    //                         slab.cureOut = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 1]");
    //                     }
    //                     break;
    //                 case "38:d2:69:da:fe:64":
    //                     if (slab.polishInA === "") {
    //                         slab.polishInA = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 1 A]");
    //                         slab.polishInASignal = data[i].RSSI;
    //                         slab.polishLine = 1;
    //                     }
    //                     break;
    //                 case "38:d2:69:dd:49:f9":
    //                     if (slab.polishInB === "") {
    //                         slab.polishInB = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 1 B]");
    //                         slab.polishInBSignal = data[i].RSSI;
    //                         slab.polishLine = 1;
    //                     }
    //                     break;
    //                 case "38:d2:69:da:de:8a":
    //                     slab.polishOutA = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 1 A]");
    //                     slab.polishOutASignal = data[i].RSSI;
    //                     slab.polishLine = 1;
    //                     break;
    //                 case "38:d2:69:db:2f:e7":
    //                     slab.polishOutB = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 1 B]");
    //                     slab.polishOutBSignal = data[i].RSSI;
    //                     slab.polishLine = 1;
    //                     break;
    //                 case "98:84:e3:e1:79:0a":
    //                     if (slab.line === 2) {
    //                         slab.cutIn = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 2]");
    //                     }
    //                     break;
    //                 case "38:d2:69:da:be:01":
    //                     if (slab.line === 2) {
    //                         slab.cutOut = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 2]");
    //                     }
    //                     break;
    //                 case "98:84:e3:e0:f5:93":
    //                     if (data[i].RSSI > slab.pressInSignal) {
    //                         slab.pressInSignal = data[i].RSSI;
    //                         slab.pressIn = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 2]");
    //                         slab.line = 2;
    //                     }
    //                     break;
    //                 case "98:84:e3:e2:25:5b":
    //                     if (slab.line === 2) {
    //                         slab.pressOut = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 2]");
    //                     }
    //                     break;
    //                 case "38:d2:69:da:9d:5e":
    //                     if (slab.line === 2 && slab.cureIn === "") {
    //                         slab.cureIn = moment(data[i].timestamp_local).local().format("LL[\r\n] HH:mm:ss");//[  Line 2]");
    //                     }
    //                     break;
    //                 case "38:d2:69:dc:d8:4f":
    //                     if (slab.line === 2) {
    //                         slab.cureOut = moment(data[i].time_out).local().format("LL[\r\n] HH:mm:ss");//[  Line 2]");
    //                     }
    //                     break;
    //                 default:
    //             }
    //         }

    //         if (slab.polishInASignal > slab.polishInBSignal) {
    //             slab.polishRotation = "A";
    //             slab.polishIn = slab.polishInA;
    //             if (slab.polishOutA !== "") {
    //                 slab.polishOut = slab.polishOutA;
    //             } else {
    //                 slab.polishOut = slab.polishOutB;
    //             }
    //         } else {
    //             slab.polishRotation = "B";
    //             slab.polishIn = slab.polishInB;
    //             if (slab.polishOutB !== "") {
    //                 slab.polishOut = slab.polishOutB;
    //             } else {
    //                 slab.polishOut = slab.polishOutA;
    //             }
    //         }
    //     }
    //     return slab;
    return newSlabObject();
    //  catch (err) {
    //     console.log(err);
    //     return 0;
    // }
}